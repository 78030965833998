@use '@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    position: relative;
    margin: var(--spacing-core-6) 0;
    width: 100%;
    justify-content: stretch;
    flex-direction: column;
    gap: var(--spacing-core-2);

    @include mq.mq($from: medium) {
        gap: var(--spacing-core-6);
        flex-direction: row;
    }
}
