@use '@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    flex: 1 0 100%;
    max-width: 100%;
    
    @include mq.mq($from: medium) {
        flex: 1;
        max-width: calc(50% - var(--spacing-core-3));
    }
}

.text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}