@use '@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    position: relative;
    margin: var(--spacing-core-4) 0 var(--spacing-core-6) 0;
}

.list {
    padding-left: var(--spacing-core-6);
    margin: 0;

    li {
        padding-left: var(--spacing-core-2);
    }

    @include mq.mq($from: medium) {
        &.columns {
            column-count: 2;
            column-gap: var(--spacing-core-6);
        }
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}
