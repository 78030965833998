.root {
    background-color: var(--color-background-secondary-default);
    width: 100%;
    height: 100%;
    position: relative;
}

.imageWrapper {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 100%;
    background-color: transparent;
    padding: 0;
}

.lazyImage {
    position: absolute;
    width: 100%;
    height: 100%;

    img,
    picture {
        display: block;
        height: 100%;
        width: 100%;
    }

    img {
        object-fit: cover;
    }
}

.heading {
    margin-top: var(--spacing-core-4);

    :global(.theme__kerst) & {
        color: var(--color-accent-theme-holiday-default);
    }
}

.description {
    color: var(--color-foreground-secondary-default);
}

.cookieInfo {
    width: 100%;
    z-index: 1;

    .icon {
        vertical-align: middle;
    }
}
