@use '@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    width: 100%;
}

.image,
.video {
    margin-top: var(--spacing-core-2);
}

@include mq.mq($from: medium) {
    .image,
    .video {
        margin-top: var(--spacing-core-6);
    }

    .hasImagesOnly {
        display: flex;
        flex-wrap: wrap;
    }

    .hasImagesOnly .image {
        display: flex;
        align-items: flex-end;
        flex: 1 0 calc(50% - var(--spacing-core-3));
        margin-right: var(--spacing-core-3);

        + .image {
            margin-right: 0;
            margin-left: var(--spacing-core-3);
        }
    }
}
