@use '@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    position: relative;
}

.title {
    position: relative;
}

.root:not(:first-child) {
    .title,
    .subtitle:first-child {
        margin-top: var(--spacing-core-10);

        @include mq.mq($from: medium) {
            margin-top: var(--spacing-core-16);
        }
    }
}

.subtitle {
    font-weight: 500;
}
