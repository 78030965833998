.root {
    position: relative;
    overflow: hidden;
    border-radius: var(--spacing-core-4);
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center
}

.player {
    width: 100%;
    height: 100%;
    border: none;
}
