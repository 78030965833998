@use '@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    flex-flow: column;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: var(--spacing-core-10) 0;

    @include mq.mq($from: medium) {
        margin: var(--spacing-core-16) 0;
        flex-flow: row;
    }
}

.image {
    width: 163px;

    @include mq.mq($from: medium) {
        width: 111px;
    }

    @include mq.mq($from: large) {
        width: 141px;
    }

    @include mq.mq($from: extra-large) {
        width: 220px;
    }
}

.imageRight {
    order: 1;

    @include mq.mq($until: small) {
        order: 0;
    }
}

.quote {
    margin-top: var(--spacing-core-4);

    @include mq.mq($until: small) {
        margin-right: 0;
        text-align: center;
    }

    @include mq.mq($from: medium) {
        margin-right: var(--spacing-core-6);
    }
}

.text,
.author {
    text-align: inherit;
}

.quoteRight {
    text-align: center;
    margin-left: 0;

    @include mq.mq($from: medium) {
        margin-right: 0;
        margin-left: var(--spacing-core-6);
        text-align: right;
    }
}

.textCenter {
    text-align: center;
    flex: 1 0 100%;
    margin: 0;

    @include mq.mq($from: medium) {
        padding: 0 var(--spacing-core-6);
    }
}

.author {
    display: block;
    margin-top: var(--spacing-core-4);
    font-weight: bold;
}
