.root {
    color: var(--color-foreground-primary-default);
    padding: var(--spacing-core-4) var(--spacing-core-8) var(--spacing-core-4)
        var(--spacing-core-4);
    animation-name: fadeIn;
    animation-duration: var(--motion-duration-hover);
    position: absolute;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    gap: var(--spacing-core-4);
}

.attention {
    background-color: var(--color-feedback-warning-default);

    > .icon {
        color: var(--color-foreground-primary-default);
    }
}

.text {
    margin: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
