@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    margin: var(--spacing-core-6) 0;
}

.container {
    @include mq.mq($from: medium) {
        padding: 0;
    }
}

.text {
    margin-bottom: var(--spacing-core-2);
}