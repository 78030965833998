@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    padding: var(--spacing-core-4);

    @include mq.mq($from: medium) {
        padding: var(--spacing-core-6);
    }
}

.paragraph + .media {
    margin-top: var(--spacing-core-6);

    @include mq.mq($from: medium) {
        margin-top: var(--spacing-core-8);
    }
}
