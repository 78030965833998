.defaultroot {
    line-height: 150%;
    font-weight: 300;

    p, 
    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6 {
        margin: 0;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    p {
        margin: var(--spacing-core-4) 0;
    }

    ul,
    ol {
        padding: 0;
        margin: var(--spacing-core-4) 0;

        li + li {
            margin-top: var(--spacing-core-2);
        }
    }

    ol {
        margin-left: var(--spacing-core-6);

        li {
            padding-left: var(--spacing-core-2);
        }
    }

    ul li {
        list-style: none;
        padding-left: var(--spacing-core-8);
        position: relative;

        &::before {
            width: 22px;
            height: 22px;
            position: absolute;
            left: 0;
            top: 0;
            content: '\2713';
            background-color: var(--color-action-primary-default);
            border-radius: 100%;
            text-align: center;
            color: var(--color-foreground-inverted-default);
        }
    }
}
